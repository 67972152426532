// deps
import { useState } from "react";
import { useIntl } from "react-intl";
import NextLink from "next/link";
import {
  Box,
  Button,
  Link,
  Checkbox,
  FormControl,
  FormErrorMessage,
  FormLabel,
  Input,
  Text,
  VStack,
  Alert,
  AlertIcon,
  useToast,
  InputGroup,
  InputRightElement,
  HStack,
  Tooltip,
  useBoolean,
} from "@chakra-ui/react";

// components
import { IoEyeOffOutline, IoEyeOutline } from "@raiden/library/components/Icon";

// libraries
import generateApiUri from "@raiden/library/libraries/utils/generateApiUri";
import generateAdminPath from "@raiden/library/libraries/utils/generateAdminPath";

// hooks
import useApiFetcher from "@raiden/library/hooks/useApiFetcher";
import useAuthAs from "@raiden/library/hooks/auth/as";
import useForm from "@splitfire-agency/raiden-library/dist/hooks/useForm";

// helpers
import { apiGetErrors } from "@raiden/library/helpers/api";
import { authLoginSerialize } from "@raiden/library/normalizers/auth";

// constants
import { USERS_USER_TYPE_VALUE_ADMIN } from "@raiden/library/constants/users";

export default function AuthLoginContainer(props) {
  const intl = useIntl();

  const [showPassword, { toggle: toggleShowPassword }] = useBoolean(false);

  const [{ loading }, setAuthLoginResponse] = useState({ loading: false });

  const toast = useToast();

  const { login, user } = useAuthAs();

  const apiFetcher = useApiFetcher();

  const form = useForm({
    initialFields: {
      data: {
        user_type: USERS_USER_TYPE_VALUE_ADMIN,
      },
    },
    onSubmit: handleSubmit,
  });

  const isLoggedWithDifferentUserType =
    user?.user_type && USERS_USER_TYPE_VALUE_ADMIN !== user.user_type;

  /**
   * Gère la soumission du formulaire.
   */
  async function handleSubmit(fields) {
    setAuthLoginResponse({ loading: true });

    form.resetErrors();

    if (isLoggedWithDifferentUserType) {
      await apiFetcher(
        generateApiUri({
          id: "@auth.logout",
        }),
        {
          method: "POST",
        },
      ).catch(function () {
        console.warn(
          "Échec de la tentative de déconnexion alors que l’utilisateur souhaite se connecter en tant que `admin` mais qu’il est déjà connecté avec un autre utilisateur avec un type différent.",
        );
      });
    }

    await apiFetcher(
      generateApiUri({
        id: "@auth.login",
      }),
      {
        method: "POST",
        body: authLoginSerialize({ fields }),
      },
    )
      .then(function (res) {
        toast({
          id: "loginSuccess",
          title: intl.formatMessage({
            id: "raiden.admin.containers.Auth.Login.texts.loginSuccess",
            defaultMessage: "Connexion réussie !",
          }),
          status: "success",
          duration: 5000,
          position: "bottom-right",
          isClosable: true,
        });

        login(res?.data);
      })
      .catch(function (error) {
        setAuthLoginResponse({ loading: false });

        form.setErrors(
          apiGetErrors({
            error,
            detailFallback: intl.formatMessage({
              id: "raiden.admin.containers.Auth.Login.texts.loginError",
              defaultMessage: "Les identifiants de connexion sont incorrectes.",
            }),
          }),
        );
      });
  }

  return (
    <VStack spacing="1.5rem">
      {form.getError("_detail") && (
        <Alert status="error">
          <AlertIcon />

          {form.getError("_detail")}
        </Alert>
      )}

      <Text
        color="gray.700"
        fontWeight="700"
        fontSize="1.5rem"
        textAlign="center">
        {intl.formatMessage({
          id: "raiden.admin.containers.Auth.Login.texts.mainHeading",
          defaultMessage: "Accéder à votre console",
        })}
      </Text>

      <Box width="100%" as="form" action="#" onSubmit={form.onSubmit}>
        <VStack spacing="1rem" align="flex-start">
          <FormControl
            id="username"
            isRequired={true}
            isInvalid={Boolean(form.getError("data[username]"))}>
            <FormLabel>
              {intl.formatMessage({
                id: "raiden.admin.containers.Auth.Login.texts.fields.username.label",
                defaultMessage: "Votre identifiant de connexion",
              })}
            </FormLabel>

            <Input
              {...form.inputProps({ name: "data[username]" })}
              placeholder={intl.formatMessage({
                id: "raiden.admin.containers.Auth.Login.texts.fields.username.placeholder",
                defaultMessage: "dupont@exemple.fr",
              })}
              isRequired={true}
            />

            <FormErrorMessage>
              {form.getError("data[username]")}
            </FormErrorMessage>
          </FormControl>

          <FormControl
            id="password"
            isRequired={true}
            isInvalid={Boolean(form.getError("data[password]"))}>
            <FormLabel>
              {intl.formatMessage({
                id: "raiden.admin.containers.Auth.Login.texts.fields.password.label",
                defaultMessage: "Votre mot de passe",
              })}
            </FormLabel>

            <InputGroup>
              <Input
                {...form.inputProps({ name: "data[password]" })}
                pr="4.5rem"
                type={showPassword ? "text" : "password"}
                placeholder={intl.formatMessage({
                  id: "raiden.admin.containers.Auth.Login.texts.fields.password.placeholder",
                  defaultMessage: "••••••••••",
                })}
                isRequired={true}
              />

              <InputRightElement width="3.25rem">
                <HStack spacing={".5rem"}>
                  <Tooltip
                    label={intl.formatMessage({
                      id: "raiden.admin.containers.Auth.Login.texts.password.showPassword",
                      defaultMessage: "Voir le mot de passe",
                    })}>
                    <Button
                      h="1.75rem"
                      type="button"
                      size="sm"
                      onClick={toggleShowPassword}>
                      {showPassword ? <IoEyeOffOutline /> : <IoEyeOutline />}
                    </Button>
                  </Tooltip>
                </HStack>
              </InputRightElement>
            </InputGroup>

            <FormErrorMessage>
              {form.getError("data[password]")}
            </FormErrorMessage>
          </FormControl>

          <FormControl
            id="remember"
            isInvalid={Boolean(form.getError("data[remember]"))}>
            <Checkbox
              {...form.switchProps({ name: "data[remember]", value: "1" })}>
              {intl.formatMessage({
                id: "raiden.admin.containers.Auth.Login.texts.fields.remember.label",
                defaultMessage: "Se souvenir de moi ?",
              })}
            </Checkbox>

            <FormErrorMessage>
              {form.getError("data[remember]")}
            </FormErrorMessage>
          </FormControl>

          <Button type="submit" w="100%" variant="solid" isLoading={loading}>
            {intl.formatMessage({
              id: "raiden.admin.containers.Auth.Login.texts.actions.submit",
              defaultMessage: "Me connecter",
            })}
          </Button>

          <Box w="100%" textAlign="center">
            <NextLink
              href={generateAdminPath({ id: "password-recovery" })}
              passHref={true}>
              <Link color="blue.500">
                {intl.formatMessage({
                  id: "raiden.admin.containers.Auth.Login.texts.passwordRecovery",
                  defaultMessage: "Mot de passe oublié ?",
                })}
              </Link>
            </NextLink>
          </Box>
        </VStack>
      </Box>
    </VStack>
  );
}
