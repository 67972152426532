// libraries
import valueInvoke from "@splitfire-agency/raiden-library/dist/libraries/utils/valueInvoke";

const defaultWhitelist = {
  "data.username": true,
  "data.password": true,
  "data.remember": true,
  "data.user_type": true,
};

export default function authLoginSerialize({
  fields,
  whitelist = defaultWhitelist,
}) {
  whitelist = valueInvoke(whitelist, defaultWhitelist);

  return {
    data: {
      username: whitelist["data.username"] ? fields?.data?.username : undefined,

      password: whitelist["data.password"] ? fields?.data?.password : undefined,

      remember: whitelist["data.remember"] ? fields?.data?.remember : undefined,

      user_type: whitelist["data.user_type"]
        ? fields?.data?.user_type
        : undefined,
    },
  };
}
